@charset "UTF-8";
/*!
 * Bootstrap Docs (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under the Creative Commons Attribution 3.0 Unported License.
 * For details, see https://creativecommons.org/licenses/by/3.0/.
 */
/* Bootstrap */
/* Override Bootstrap default variable */
.docon {
  font-family: docons;
  font-size: inherit;
  speak: none;
  display: inline-block;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  text-align: center;
  direction: ltr;
  line-height: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: docons;
  src: url("../fonts/docons.74f120c0.eot");
  src: url("../fonts/docons.74f120c0.eot") format("embedded-opentype"), url("../fonts/docons.6bdf2135.woff2") format("woff2"), url("../fonts/docons.8d7a363b.woff") format("woff"), url("../fonts/docons.b73af7b3.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: SegoeUI;
  src: url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.eot);
  src: local("Segoe UI"), url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.woff2) format("woff2"), url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.woff) format("woff"), url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.ttf) format("ttf");
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: SegoeUI;
  src: url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semilight/latest.eot);
  src: local("Segoe UI"), url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semilight/latest.woff2) format("woff2"), url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semilight/latest.woff) format("woff"), url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semilight/latest.ttf) format("ttf");
  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-family: SegoeUI;
  src: url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.eot);
  src: local("Segoe UI"), url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.woff2) format("woff2"), url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.woff) format("woff"), url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.ttf) format("ttf");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: SegoeUI;
  src: url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semibold/latest.eot);
  src: local("Segoe UI"), url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semibold/latest.woff2) format("woff2"), url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semibold/latest.woff) format("woff"), url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semibold/latest.ttf) format("ttf");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: SegoeUI;
  src: url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.eot);
  src: local("Segoe UI"), url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.woff2) format("woff2"), url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.woff) format("woff"), url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.ttf) format("ttf");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: SegoeUI;
  src: local("Apple Color Emoji"), local("Android Emoji"), local("Segoe UI Emoji"), local(EmojiSymbols), local(Symbola);
  unicode-range: U+1f300-1f5ff, U+1f600-1f64f, U+1f680-1f6ff, U+026??;
}

@font-face {
  font-family: 'Icons';
  src: url("../fonts/open-iconic.eot");
  src: url("../fonts/open-iconic.eot?#iconic-sm") format("embedded-opentype"), url("../fonts/open-iconic.woff") format("woff"), url("../fonts/open-iconic.ttf") format("truetype"), url("../fonts/open-iconic.svg#iconic-sm") format("svg");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: Segoe UI,SegoeUI,Segoe WP,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
  font-weight: 400;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

.oi {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Icons';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.oi:empty:before {
  width: 1em;
  text-align: center;
  box-sizing: content-box;
}

.oi.oi-align-center:before {
  text-align: center;
}

.oi.oi-align-left:before {
  text-align: left;
}

.oi.oi-align-right:before {
  text-align: right;
}

.oi.oi-flip-horizontal:before {
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.oi.oi-flip-vertical:before {
  -webkit-transform: scale(1, -1);
  transform: scale(1, -1);
}

.oi.oi-flip-horizontal-vertical:before {
  -webkit-transform: scale(-1, -1);
  transform: scale(-1, -1);
}

.oi-account-login:before {
  content: '\e000';
}

.oi-account-logout:before {
  content: '\e001';
}

.oi-action-redo:before {
  content: '\e002';
}

.oi-action-undo:before {
  content: '\e003';
}

.oi-align-center:before {
  content: '\e004';
}

.oi-align-left:before {
  content: '\e005';
}

.oi-align-right:before {
  content: '\e006';
}

.oi-aperture:before {
  content: '\e007';
}

.oi-arrow-bottom:before {
  content: '\e008';
}

.oi-arrow-circle-bottom:before {
  content: '\e009';
}

.oi-arrow-circle-left:before {
  content: '\e00a';
}

.oi-arrow-circle-right:before {
  content: '\e00b';
}

.oi-arrow-circle-top:before {
  content: '\e00c';
}

.oi-arrow-left:before {
  content: '\e00d';
}

.oi-arrow-right:before {
  content: '\e00e';
}

.oi-arrow-thick-bottom:before {
  content: '\e00f';
}

.oi-arrow-thick-left:before {
  content: '\e010';
}

.oi-arrow-thick-right:before {
  content: '\e011';
}

.oi-arrow-thick-top:before {
  content: '\e012';
}

.oi-arrow-top:before {
  content: '\e013';
}

.oi-audio-spectrum:before {
  content: '\e014';
}

.oi-audio:before {
  content: '\e015';
}

.oi-badge:before {
  content: '\e016';
}

.oi-ban:before {
  content: '\e017';
}

.oi-bar-chart:before {
  content: '\e018';
}

.oi-basket:before {
  content: '\e019';
}

.oi-battery-empty:before {
  content: '\e01a';
}

.oi-battery-full:before {
  content: '\e01b';
}

.oi-beaker:before {
  content: '\e01c';
}

.oi-bell:before {
  content: '\e01d';
}

.oi-bluetooth:before {
  content: '\e01e';
}

.oi-bold:before {
  content: '\e01f';
}

.oi-bolt:before {
  content: '\e020';
}

.oi-book:before {
  content: '\e021';
}

.oi-bookmark:before {
  content: '\e022';
}

.oi-box:before {
  content: '\e023';
}

.oi-briefcase:before {
  content: '\e024';
}

.oi-british-pound:before {
  content: '\e025';
}

.oi-browser:before {
  content: '\e026';
}

.oi-brush:before {
  content: '\e027';
}

.oi-bug:before {
  content: '\e028';
}

.oi-bullhorn:before {
  content: '\e029';
}

.oi-calculator:before {
  content: '\e02a';
}

.oi-calendar:before {
  content: '\e02b';
}

.oi-camera-slr:before {
  content: '\e02c';
}

.oi-caret-bottom:before {
  content: '\e02d';
}

.oi-caret-left:before {
  content: '\e02e';
}

.oi-caret-right:before {
  content: '\e02f';
}

.oi-caret-top:before {
  content: '\e030';
}

.oi-cart:before {
  content: '\e031';
}

.oi-chat:before {
  content: '\e032';
}

.oi-check:before {
  content: '\e033';
}

.oi-chevron-bottom:before {
  content: '\e034';
}

.oi-chevron-left:before {
  content: '\e035';
}

.oi-chevron-right:before {
  content: '\e036';
}

.oi-chevron-top:before {
  content: '\e037';
}

.oi-circle-check:before {
  content: '\e038';
}

.oi-circle-x:before {
  content: '\e039';
}

.oi-clipboard:before {
  content: '\e03a';
}

.oi-clock:before {
  content: '\e03b';
}

.oi-cloud-download:before {
  content: '\e03c';
}

.oi-cloud-upload:before {
  content: '\e03d';
}

.oi-cloud:before {
  content: '\e03e';
}

.oi-cloudy:before {
  content: '\e03f';
}

.oi-code:before {
  content: '\e040';
}

.oi-cog:before {
  content: '\e041';
}

.oi-collapse-down:before {
  content: '\e042';
}

.oi-collapse-left:before {
  content: '\e043';
}

.oi-collapse-right:before {
  content: '\e044';
}

.oi-collapse-up:before {
  content: '\e045';
}

.oi-command:before {
  content: '\e046';
}

.oi-comment-square:before {
  content: '\e047';
}

.oi-compass:before {
  content: '\e048';
}

.oi-contrast:before {
  content: '\e049';
}

.oi-copywriting:before {
  content: '\e04a';
}

.oi-credit-card:before {
  content: '\e04b';
}

.oi-crop:before {
  content: '\e04c';
}

.oi-dashboard:before {
  content: '\e04d';
}

.oi-data-transfer-download:before {
  content: '\e04e';
}

.oi-data-transfer-upload:before {
  content: '\e04f';
}

.oi-delete:before {
  content: '\e050';
}

.oi-dial:before {
  content: '\e051';
}

.oi-document:before {
  content: '\e052';
}

.oi-dollar:before {
  content: '\e053';
}

.oi-double-quote-sans-left:before {
  content: '\e054';
}

.oi-double-quote-sans-right:before {
  content: '\e055';
}

.oi-double-quote-serif-left:before {
  content: '\e056';
}

.oi-double-quote-serif-right:before {
  content: '\e057';
}

.oi-droplet:before {
  content: '\e058';
}

.oi-eject:before {
  content: '\e059';
}

.oi-elevator:before {
  content: '\e05a';
}

.oi-ellipses:before {
  content: '\e05b';
}

.oi-envelope-closed:before {
  content: '\e05c';
}

.oi-envelope-open:before {
  content: '\e05d';
}

.oi-euro:before {
  content: '\e05e';
}

.oi-excerpt:before {
  content: '\e05f';
}

.oi-expand-down:before {
  content: '\e060';
}

.oi-expand-left:before {
  content: '\e061';
}

.oi-expand-right:before {
  content: '\e062';
}

.oi-expand-up:before {
  content: '\e063';
}

.oi-external-link:before {
  content: '\e064';
}

.oi-eye:before {
  content: '\e065';
}

.oi-eyedropper:before {
  content: '\e066';
}

.oi-file:before {
  content: '\e067';
}

.oi-fire:before {
  content: '\e068';
}

.oi-flag:before {
  content: '\e069';
}

.oi-flash:before {
  content: '\e06a';
}

.oi-folder:before {
  content: '\e06b';
}

.oi-fork:before {
  content: '\e06c';
}

.oi-fullscreen-enter:before {
  content: '\e06d';
}

.oi-fullscreen-exit:before {
  content: '\e06e';
}

.oi-globe:before {
  content: '\e06f';
}

.oi-graph:before {
  content: '\e070';
}

.oi-grid-four-up:before {
  content: '\e071';
}

.oi-grid-three-up:before {
  content: '\e072';
}

.oi-grid-two-up:before {
  content: '\e073';
}

.oi-hard-drive:before {
  content: '\e074';
}

.oi-header:before {
  content: '\e075';
}

.oi-headphones:before {
  content: '\e076';
}

.oi-heart:before {
  content: '\e077';
}

.oi-home:before {
  content: '\e078';
}

.oi-image:before {
  content: '\e079';
}

.oi-inbox:before {
  content: '\e07a';
}

.oi-infinity:before {
  content: '\e07b';
}

.oi-info:before {
  content: '\e07c';
}

.oi-italic:before {
  content: '\e07d';
}

.oi-justify-center:before {
  content: '\e07e';
}

.oi-justify-left:before {
  content: '\e07f';
}

.oi-justify-right:before {
  content: '\e080';
}

.oi-key:before {
  content: '\e081';
}

.oi-laptop:before {
  content: '\e082';
}

.oi-layers:before {
  content: '\e083';
}

.oi-lightbulb:before {
  content: '\e084';
}

.oi-link-broken:before {
  content: '\e085';
}

.oi-link-intact:before {
  content: '\e086';
}

.oi-list-rich:before {
  content: '\e087';
}

.oi-list:before {
  content: '\e088';
}

.oi-location:before {
  content: '\e089';
}

.oi-lock-locked:before {
  content: '\e08a';
}

.oi-lock-unlocked:before {
  content: '\e08b';
}

.oi-loop-circular:before {
  content: '\e08c';
}

.oi-loop-square:before {
  content: '\e08d';
}

.oi-loop:before {
  content: '\e08e';
}

.oi-magnifying-glass:before {
  content: '\e08f';
}

.oi-map-marker:before {
  content: '\e090';
}

.oi-map:before {
  content: '\e091';
}

.oi-media-pause:before {
  content: '\e092';
}

.oi-media-play:before {
  content: '\e093';
}

.oi-media-record:before {
  content: '\e094';
}

.oi-media-skip-backward:before {
  content: '\e095';
}

.oi-media-skip-forward:before {
  content: '\e096';
}

.oi-media-step-backward:before {
  content: '\e097';
}

.oi-media-step-forward:before {
  content: '\e098';
}

.oi-media-stop:before {
  content: '\e099';
}

.oi-medical-cross:before {
  content: '\e09a';
}

.oi-menu:before {
  content: '\e09b';
}

.oi-microphone:before {
  content: '\e09c';
}

.oi-minus:before {
  content: '\e09d';
}

.oi-monitor:before {
  content: '\e09e';
}

.oi-moon:before {
  content: '\e09f';
}

.oi-move:before {
  content: '\e0a0';
}

.oi-musical-note:before {
  content: '\e0a1';
}

.oi-paperclip:before {
  content: '\e0a2';
}

.oi-pencil:before {
  content: '\e0a3';
}

.oi-people:before {
  content: '\e0a4';
}

.oi-person:before {
  content: '\e0a5';
}

.oi-phone:before {
  content: '\e0a6';
}

.oi-pie-chart:before {
  content: '\e0a7';
}

.oi-pin:before {
  content: '\e0a8';
}

.oi-play-circle:before {
  content: '\e0a9';
}

.oi-plus:before {
  content: '\e0aa';
}

.oi-power-standby:before {
  content: '\e0ab';
}

.oi-print:before {
  content: '\e0ac';
}

.oi-project:before {
  content: '\e0ad';
}

.oi-pulse:before {
  content: '\e0ae';
}

.oi-puzzle-piece:before {
  content: '\e0af';
}

.oi-question-mark:before {
  content: '\e0b0';
}

.oi-rain:before {
  content: '\e0b1';
}

.oi-random:before {
  content: '\e0b2';
}

.oi-reload:before {
  content: '\e0b3';
}

.oi-resize-both:before {
  content: '\e0b4';
}

.oi-resize-height:before {
  content: '\e0b5';
}

.oi-resize-width:before {
  content: '\e0b6';
}

.oi-rss-alt:before {
  content: '\e0b7';
}

.oi-rss:before {
  content: '\e0b8';
}

.oi-script:before {
  content: '\e0b9';
}

.oi-share-boxed:before {
  content: '\e0ba';
}

.oi-share:before {
  content: '\e0bb';
}

.oi-shield:before {
  content: '\e0bc';
}

.oi-signal:before {
  content: '\e0bd';
}

.oi-signpost:before {
  content: '\e0be';
}

.oi-sort-ascending:before {
  content: '\e0bf';
}

.oi-sort-descending:before {
  content: '\e0c0';
}

.oi-spreadsheet:before {
  content: '\e0c1';
}

.oi-star:before {
  content: '\e0c2';
}

.oi-sun:before {
  content: '\e0c3';
}

.oi-tablet:before {
  content: '\e0c4';
}

.oi-tag:before {
  content: '\e0c5';
}

.oi-tags:before {
  content: '\e0c6';
}

.oi-target:before {
  content: '\e0c7';
}

.oi-task:before {
  content: '\e0c8';
}

.oi-terminal:before {
  content: '\e0c9';
}

.oi-text:before {
  content: '\e0ca';
}

.oi-thumb-down:before {
  content: '\e0cb';
}

.oi-thumb-up:before {
  content: '\e0cc';
}

.oi-timer:before {
  content: '\e0cd';
}

.oi-transfer:before {
  content: '\e0ce';
}

.oi-trash:before {
  content: '\e0cf';
}

.oi-underline:before {
  content: '\e0d0';
}

.oi-vertical-align-bottom:before {
  content: '\e0d1';
}

.oi-vertical-align-center:before {
  content: '\e0d2';
}

.oi-vertical-align-top:before {
  content: '\e0d3';
}

.oi-video:before {
  content: '\e0d4';
}

.oi-volume-high:before {
  content: '\e0d5';
}

.oi-volume-low:before {
  content: '\e0d6';
}

.oi-volume-off:before {
  content: '\e0d7';
}

.oi-warning:before {
  content: '\e0d8';
}

.oi-wifi:before {
  content: '\e0d9';
}

.oi-wrench:before {
  content: '\e0da';
}

.oi-x:before {
  content: '\e0db';
}

.oi-yen:before {
  content: '\e0dc';
}

.oi-zoom-in:before {
  content: '\e0dd';
}

.oi-zoom-out:before {
  content: '\e0de';
}

.docon-chevron-right-light:before {
  content: "\FF34";
}

.docon-edit-outline:before {
  content: "\FF0F";
}

.docon-sharing:before {
  content: "\FF0B";
}

.docon-search:before {
  content: "\E721";
}

.nav {
  -ms-flex-direction: column;
  flex-direction: column;
}

.bd-navbar {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  background-color: #fff;
  border-bottom: 1px solid #e3e3e3;
  padding-left: 4vw;
  padding-right: 6vw;
}

@media (max-width: 991.98px) {
  .bd-navbar {
    padding-right: .5rem;
    padding-left: .5rem;
    max-width: 100%;
    width: 100%;
  }
  .bd-navbar .navbar-nav-scroll {
    max-width: 100%;
  }
  .bd-navbar .navbar-nav-scroll .navbar-nav {
    padding-bottom: 2rem;
    padding-left: 24px;
    padding-right: 24px;
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  }
}

@media (min-width: 768px) {
  @supports ((position: -webkit-sticky) or (position: sticky)) {
    .bd-navbar {
      top: 0;
      z-index: 1071;
    }
  }
}

.bd-navbar .title {
  color: #171717;
  font-size: 1.1875rem;
  font-weight: 600;
  line-height: 1.125;
  -ms-flex-item-align: center;
  align-self: center;
  border-bottom: 2px solid transparent;
}

.bd-navbar .title:hover {
  text-decoration: none;
  border-bottom: none;
}

.bd-navbar .title:hover:after {
  content: "";
  display: block;
  border-bottom: 2px solid #171717;
}

.bd-navbar .navbar-nav .nav-link {
  padding-right: .5rem;
  padding-left: .5rem;
  color: white;
  font-size: 1.125rem;
  cursor: pointer;
}

.bd-navbar .navbar-nav .nav-link.active, .bd-navbar .navbar-nav .nav-link:hover {
  color: #000;
  background-color: transparent;
}

.bd-navbar .navbar-nav .nav-link.active {
  font-weight: 400;
}

.bd-navbar .navbar-nav-svg {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  vertical-align: text-top;
}

.bd-navbar .dropdown-menu {
  font-size: 0.875rem;
}

.bd-navbar .dropdown-item.active {
  font-weight: 600;
  color: #212529;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23292b2c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: .4rem .6rem;
  background-size: .75rem .75rem;
}

.tooltip {
  z-index: 1100;
}

.tooltip-inner {
  color: #000;
  background-color: #fff;
  border: 2px solid #000;
  border-radius: 2px;
}

.subnavbar-nav {
  display: -ms-flexbox;
  display: flex;
  border-bottom: 1px solid #e3e3e3;
  padding-left: 5vw;
  padding-right: 5vw;
}

.subnavbar-nav ul, .subnavbar-nav ol {
  margin: 0;
}

.subnavbar-nav .btn-clipboard {
  position: inherit;
}

@media (max-width: 991.98px) {
  .subnavbar-nav {
    background: #fafafa;
    padding-left: 2vw;
    padding-right: 2vw;
  }
}

.subnavbar-nav .nav-link {
  padding: 0.2rem .75em;
  font-size: .875rem;
  cursor: pointer;
  color: black;
}

.subnavbar-nav .nav-item:hover {
  background: #fafafa;
}

.subnavbar-nav .breadcrumb {
  margin: 0;
  padding: 0.2rem 0;
  background-color: transparent;
  font-size: .875rem;
}

.subnavbar-nav .breadcrumb a {
  color: #0065b3;
}

.subnavbar-nav .actions {
  display: -ms-flexbox;
  display: flex;
  margin-right: 15px;
  list-style-type: none;
}

@media (max-width: 991.98px) {
  .subnavbar-nav .actions {
    margin-right: 0;
    -webkit-padding-start: 0;
    -moz-padding-start: 0;
    padding-inline-start: 0;
  }
}

.bd-search {
  position: relative;
  padding: 0;
  /* Firefox 19+ */
  /* Firefox 18- */
}

@media (min-width: 768px) {
  .bd-search {
    margin-left: -15px;
  }
}

.bd-search ::-webkit-input-placeholder {
  color: black;
}

.bd-search ::-moz-placeholder {
  color: black;
}

.bd-search :-moz-placeholder {
  color: black;
}

.bd-search :-ms-input-placeholder {
  color: black;
}

.bd-search:hover, .bd-search:focus, .bd-search:focus-within, .bd-search:active {
  width: 100%;
}

.bd-search:hover .form-control, .bd-search:focus .form-control, .bd-search:focus-within .form-control, .bd-search:active .form-control {
  text-align: left;
  border: 2px solid #0065b3;
  box-shadow: 0 0 0 0.125rem #0065b3;
}

.bd-search:hover .icon, .bd-search:focus .icon, .bd-search:focus-within .icon, .bd-search:active .icon {
  display: none;
}

.bd-search .form-control:focus {
  box-shadow: none;
}

.bd-search .form-control {
  font-size: .875rem;
  border-radius: 2px;
  text-align: right;
  border: 1px solid transparent;
}

.bd-search-docs-toggle {
  -ms-flex-item-align: end;
  align-self: flex-end;
  line-height: 1;
  color: #212529;
}

.bd-masthead {
  position: relative;
  padding: 3rem 15px;
}

.bd-masthead h1 {
  font-size: 4rem;
  line-height: 1;
}

@media (max-width: 1200px) {
  .bd-masthead h1 {
    font-size: calc(1.525rem + 3.3vw) ;
  }
}

.bd-masthead .btn {
  padding: .8rem 2rem;
  font-weight: 600;
  font-size: 1.25rem;
}

.bd-masthead .carbonad {
  margin-top: 0 !important;
  margin-bottom: -3rem !important;
}

@media (min-width: 576px) {
  .bd-masthead {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .bd-masthead .carbonad {
    margin-bottom: 0 !important;
  }
}

@media (min-width: 768px) {
  .bd-masthead .carbonad {
    margin-top: 3rem !important;
  }
}

.half-rule {
  width: 6rem;
  margin: 2.5rem 0;
}

.masthead-followup .bd-clipboard {
  display: none;
}

.masthead-followup .highlight {
  padding: .5rem 0;
  background-color: transparent;
}

a {
  color: #0065b3;
}

#date {
  margin-right: 7px;
  color: #5e5e5e;
  font-size: .875rem;
}

#date:after {
  padding-left: 5px;
  padding-right: 5px;
  content: "•";
}

#contributors a {
  margin-right: 5px;
}

#contributors img {
  width: 24px;
  height: 24px;
  border-radius: 24px;
}

.bd-content {
  -ms-flex-order: 1;
  order: 1;
  padding-top: .25em;
}

.bd-content > h2[id],
.bd-content > h3[id],
.bd-content > h4[id] {
  pointer-events: none;
}

.bd-content > h2[id]::before,
.bd-content > h3[id]::before,
.bd-content > h4[id]::before {
  display: block;
  height: 6rem;
  margin-top: -6rem;
  content: "";
}

.bd-content > table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
}

@media (max-width: 991.98px) {
  .bd-content > table {
    display: block;
    overflow-x: auto;
  }
  .bd-content > table.table-bordered {
    border: 0;
  }
}

.bd-content > table > thead > tr > th,
.bd-content > table > thead > tr > td,
.bd-content > table > tbody > tr > th,
.bd-content > table > tbody > tr > td,
.bd-content > table > tfoot > tr > th,
.bd-content > table > tfoot > tr > td {
  padding: 0.75rem;
  vertical-align: top;
  border: 1px solid #dee2e6;
}

.bd-content > table > thead > tr > th > p:last-child,
.bd-content > table > thead > tr > td > p:last-child,
.bd-content > table > tbody > tr > th > p:last-child,
.bd-content > table > tbody > tr > td > p:last-child,
.bd-content > table > tfoot > tr > th > p:last-child,
.bd-content > table > tfoot > tr > td > p:last-child {
  margin-bottom: 0;
}

.bd-content > table td:first-child > code {
  white-space: nowrap;
}

.bd-content-title {
  display: block;
  font-weight: 600;
  pointer-events: auto;
}

.bd-content > h2 {
  font-size: 1.75rem;
}

.bd-content > h3 {
  font-size: 1.1875rem;
}

.bd-content > h4 {
  font-size: 1.5rem;
}

@media (max-width: 1200px) {
  .bd-content > h4 {
    font-size: calc(1.275rem + 0.3vw) ;
  }
}

.bd-content > h2:not(:first-child) {
  margin-top: 3rem;
}

.bd-content > h3 {
  margin-top: 1.5rem;
}

.bd-content > ul li,
.bd-content > ol li {
  margin-bottom: .25rem;
}

.bd-content > p img {
  width: 100%;
}

.bd-content > p {
  word-break: break-word;
}

@media (min-width: 992px) {
  .bd-content > ul,
  .bd-content > ol,
  .bd-content > p {
    max-width: 80%;
  }
}

.bd-title {
  margin-top: 1rem;
  margin-bottom: .5rem;
  font-weight: 600;
  font-size: 2.5rem;
}

.bd-lead {
  font-size: 1.5rem;
  font-weight: 300;
}

@media (max-width: 1200px) {
  .bd-lead {
    font-size: calc(1.275rem + 0.3vw) ;
  }
}

@media (min-width: 992px) {
  .bd-lead {
    max-width: 80%;
  }
}

.bd-text-purple {
  color: #e3e3e3;
}

.bd-text-purple-bright {
  color: #0065b3;
}

@media (min-width: 768px) {
  .container-fluid {
    padding-left: 5vw;
    padding-right: 5vw;
  }
}

.skippy {
  display: block;
  padding: 1em;
  color: #fff;
  text-align: center;
  background-color: #e3e3e3;
  outline: 0;
}

.skippy:hover {
  color: #fff;
}

.skippy-text {
  padding: .5em;
  outline: 1px dotted;
}

.bd-toc {
  -ms-flex-order: 2;
  order: 2;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  font-size: 0.875rem;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .bd-toc {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    height: 100vh;
    overflow-y: auto;
  }
}

.bd-toc h6 {
  padding: .125rem 0.875rem;
}

.section-nav {
  padding-left: 0;
}

.section-nav ul {
  padding-left: .875rem;
}

#toc .nav-link.active,
#toc .nav-link.active:focus,
#toc .nav-link.active:hover {
  color: #0065b3;
  border-color: #0065b3;
}

#toc .nav > li > a {
  color: #0065b3;
}

.toc-entry {
  display: block;
}

.toc-entry.active > a {
  border-color: #0065b3;
}

.toc-entry a {
  display: block;
  padding: .125rem 1.5rem;
  color: #0065b3;
  border-left: 3px solid transparent;
}

.toc-entry a:hover {
  color: #007bff;
  text-decoration: underline;
}

.toc-entry a:selected {
  border-left: 3px solid #0065b3;
}

.toc-entry a.current {
  background: #000;
}

.bd-sidebar {
  -ms-flex-order: 0;
  order: 0;
}

@media (min-width: 768px) {
  @supports ((position: -webkit-sticky) or (position: sticky)) {
    .bd-sidebar {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1000;
      height: 100vh;
    }
  }
}

@media (min-width: 1200px) {
  .bd-sidebar {
    -ms-flex: 0 1 320px;
    flex: 0 1 320px;
  }
}

.bd-links {
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-right: -15px;
  margin-left: -15px;
}

@media (min-width: 768px) {
  @supports ((position: -webkit-sticky) or (position: sticky)) {
    .bd-links {
      max-height: calc(100vh - 5rem);
      overflow-y: auto;
    }
  }
}

@media (min-width: 768px) {
  .bd-links {
    display: block !important;
  }
}

.tree-expander {
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.tree-expander, .tree-item.is-leaf {
  outline-offset: -.125rem;
}

.tree-expander, .tree-item.is-leaf {
  display: block;
  padding-left: 1rem;
  padding-top: 2px;
  padding-bottom: 2px;
}

.tree-expander-indicator {
  display: inline-block;
  position: absolute;
  top: 6px;
  left: 3px;
  color: #5e5e5e;
  color: var(--text-subtle);
  font-size: .55rem;
  font-weight: 600;
  transition: -webkit-transform .15s ease-in-out;
  transition: transform .15s ease-in-out;
  transition: transform .15s ease-in-out, -webkit-transform .15s ease-in-out;
}

.tree-expander-indicator.active {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.docon-chevron-right-light:before {
  content: "\FF34";
}

.bd-toc-link {
  display: block;
  padding: 0 .675rem;
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.65);
}

.bd-toc-link:hover {
  color: rgba(0, 0, 0, 0.85);
  text-decoration: none;
}

.bd-toc-item.active .bd-toc-link {
  color: rgba(0, 0, 0, 0.85);
  background-color: transparent;
  font-weight: 400;
}

.bd-toc-item.active .bd-toc-link:hover {
  background-color: transparent;
}

.bd-toc-item.active .bd-toc-link:first-child .tree-expander-indicator {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.bd-toc-item.active .bd-toc-link .tree-expander-indicator.active {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.bd-toc-item.active.top-level .bd-toc-link {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.999);
}

.bd-sidenav {
  display: block;
}

.bd-sidenav .bd-sidenav {
  padding-left: 15px;
}

.active .active:first-child .tree-expander-indicator {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.top-level .tree-expander-indicator {
  display: none;
}

.top-level.bd-toc-item.active {
  margin-bottom: 0;
}

.top-level.active {
  font-weight: 600;
  background: #f2f2f2;
  color: rgba(0, 0, 0, 0.999);
}

.bd-sidebar .bd-toc-item a {
  padding: .25rem 1.5rem;
}

.bd-sidebar .nav > li a {
  display: block;
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.65);
}

.bd-sidebar .nav > li a:hover {
  color: rgba(0, 0, 0, 0.85);
  text-decoration: underline;
  background-color: transparent;
}

.bd-sidebar .nav > li.active ul li a {
  color: rgba(0, 0, 0, 0.65);
}

.bd-sidebar .nav > li.active a {
  color: rgba(0, 0, 0, 0.999);
}

.bd-toc-item.active .bd-sidenav {
  display: block;
}

.bd-toc-item.active .bd-sidenav li.active:not(.bd-toc-link) a {
  font-weight: 600;
  background: #f2f2f2;
  color: rgba(0, 0, 0, 0.999);
}

.bd-toc-item.active .bd-sidenav li.active:not(.bd-toc-link) ul li a {
  color: rgba(0, 0, 0, 0.85);
  background-color: transparent;
  font-weight: 400;
}

.bd-toc-item.active .bd-sidenav li.active:not(.bd-toc-link) ul li.active a {
  font-weight: 600;
  background: #f2f2f2;
  color: rgba(0, 0, 0, 0.999);
}

.bd-toc-item .bd-sidenav {
  display: none;
  padding-left: .675em;
}

.bd-toc-item .bd-sidenav .bd-subsidenav {
  display: none;
  margin-left: 2em;
  font-weight: 600;
  background: #f2f2f2;
  color: rgba(0, 0, 0, 0.999);
}

.bd-toc-item .bd-sidenav .bd-subsidenav-active {
  margin-left: 2em;
  display: block;
}

.bd-toc-item .bd-sidenav .bd-sidenav-active {
  padding-left: 0;
}

.bd-toc-item .bd-sidenav .bd-toc-link {
  padding: 0;
}

.bd-toc-item .bd-sidenav-active {
  padding-left: .675em;
}

.bd-toc-item .bd-sidenav-active .bd-toc-link {
  padding: 0;
}

.bd-toc-item .bd-sidenav-active li.active a {
  font-weight: 600;
  background: #f2f2f2;
  color: rgba(0, 0, 0, 0.999);
}

.bd-toc-item .bd-sidenav-active li.active ul li.active a {
  font-weight: 600;
  background: #f2f2f2;
  color: rgba(0, 0, 0, 0.999);
}

.bd-toc-item .bd-sidenav-active li.active ul li a {
  color: rgba(0, 0, 0, 0.85);
  background-color: transparent;
  font-weight: 400;
}

.bd-toc-item .bd-sidenav-active .bd-subsidenav {
  display: none;
}

.bd-toc-item .bd-sidenav-active .tree-expander-indicator {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  background: inherit;
  color: inherit;
}

.bd-toc-item .bd-sidenav-active .bd-subsidenav-active {
  display: block;
  margin-left: 2em;
}

.bd-toc-item .bd-sidenav-active .bd-subsidenav-active .active a {
  font-weight: 600;
  background: #f2f2f2;
  color: rgba(0, 0, 0, 0.999);
}

.bd-footer {
  font-size: 0.875rem;
  text-align: center;
  background-color: #f7f7f7;
}

.bd-footer a {
  font-weight: 600;
  color: #495057;
}

.bd-footer a:hover, .bd-footer a:focus {
  color: #007bff;
}

.bd-footer p {
  margin-bottom: 0;
}

@media (min-width: 576px) {
  .bd-footer {
    text-align: left;
  }
}

.bd-footer-links {
  padding-left: 0;
  margin-bottom: 1rem;
}

.bd-footer-links li {
  display: inline-block;
}

.bd-footer-links li + li {
  margin-left: 1rem;
}

.bd-example-row .row > .col,
.bd-example-row .row > [class^="col-"] {
  padding-top: .75rem;
  padding-bottom: .75rem;
  background-color: rgba(86, 61, 124, 0.15);
  border: 1px solid rgba(86, 61, 124, 0.2);
}

.bd-example-row .row + .row {
  margin-top: 1rem;
}

.bd-example-row .flex-items-top,
.bd-example-row .flex-items-middle,
.bd-example-row .flex-items-bottom {
  min-height: 6rem;
  background-color: rgba(255, 0, 0, 0.1);
}

.bd-example-row-flex-cols .row {
  min-height: 10rem;
  background-color: rgba(255, 0, 0, 0.1);
}

.bd-highlight {
  background-color: rgba(227, 227, 227, 0.15);
  border: 1px solid rgba(227, 227, 227, 0.15);
}

.example-container {
  width: 800px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.example-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.example-content-main {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 576px) {
  .example-content-main {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 992px) {
  .example-content-main {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
}

.example-content-secondary {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 576px) {
  .example-content-secondary {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 992px) {
  .example-content-secondary {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}

.bd-example-container {
  min-width: 16rem;
  max-width: 25rem;
  margin-right: auto;
  margin-left: auto;
}

.bd-example-container-header {
  height: 3rem;
  margin-bottom: .5rem;
  background-color: white;
  border-radius: 0.25rem;
}

.bd-example-container-sidebar {
  float: right;
  width: 4rem;
  height: 8rem;
  background-color: #80bdff;
  border-radius: 0.25rem;
}

.bd-example-container-body {
  height: 8rem;
  margin-right: 4.5rem;
  background-color: white;
  border-radius: 0.25rem;
}

.bd-example-container-fluid {
  max-width: none;
}

.bd-example {
  position: relative;
  padding: 1rem;
  margin: 1rem -15px 0;
  border: solid #f8f9fa;
  border-width: .2rem 0 0;
}

.bd-example::after {
  display: block;
  clear: both;
  content: "";
}

@media (min-width: 576px) {
  .bd-example {
    padding: 1.5rem;
    margin-right: 0;
    margin-left: 0;
    border-width: .2rem;
  }
}

.bd-example + .highlight,
.bd-example + .clipboard + .highlight {
  margin-top: 0;
}

.bd-example + p {
  margin-top: 2rem;
}

.bd-example .pos-f-t {
  position: relative;
  margin: -1rem;
}

@media (min-width: 576px) {
  .bd-example .pos-f-t {
    margin: -1.5rem;
  }
}

.bd-example .custom-file-input:lang(es) ~ .custom-file-label::after {
  content: "Elegir";
}

.bd-example > .form-control + .form-control {
  margin-top: .5rem;
}

.bd-example > .nav + .nav,
.bd-example > .alert + .alert,
.bd-example > .navbar + .navbar,
.bd-example > .progress + .progress,
.bd-example > .progress + .btn {
  margin-top: 1rem;
}

.bd-example > .dropdown-menu:first-child {
  position: static;
  display: block;
}

.bd-example > .form-group:last-child {
  margin-bottom: 0;
}

.bd-example > .close {
  float: none;
}

.bd-example-type .table td {
  padding: 1rem 0;
  border-color: #eee;
}

.bd-example-type .table tr:first-child td {
  border-top: 0;
}

.bd-example-type h1,
.bd-example-type h2,
.bd-example-type h3,
.bd-example-type h4,
.bd-example-type h5,
.bd-example-type h6 {
  margin-top: 0;
  margin-bottom: 0;
}

.bd-example-bg-classes p {
  padding: 1rem;
}

.bd-example > svg + svg,
.bd-example > img + img {
  margin-left: .5rem;
}

.bd-example > .btn,
.bd-example > .btn-group {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.bd-example > .btn-toolbar + .btn-toolbar {
  margin-top: .5rem;
}

.bd-example-control-sizing select,
.bd-example-control-sizing input[type="text"] + input[type="text"] {
  margin-top: .5rem;
}

.bd-example-form .input-group {
  margin-bottom: .5rem;
}

.bd-example > textarea.form-control {
  resize: vertical;
}

.bd-example > .list-group {
  max-width: 400px;
}

.bd-example > [class*="list-group-horizontal"] {
  max-width: 100%;
}

.bd-example .fixed-top,
.bd-example .sticky-top {
  position: static;
  margin: -1rem -1rem 1rem;
}

.bd-example .fixed-bottom {
  position: static;
  margin: 1rem -1rem -1rem;
}

@media (min-width: 576px) {
  .bd-example .fixed-top,
  .bd-example .sticky-top {
    margin: -1.5rem -1.5rem 1rem;
  }
  .bd-example .fixed-bottom {
    margin: 1rem -1.5rem -1.5rem;
  }
}

.bd-example .pagination {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.modal {
  z-index: 1072;
}

.modal .tooltip,
.modal .popover {
  z-index: 1073;
}

.modal-backdrop {
  z-index: 1071;
}

.bd-example-modal {
  background-color: #fafafa;
}

.bd-example-modal .modal {
  position: relative;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  z-index: 1;
  display: block;
}

.bd-example-modal .modal-dialog {
  left: auto;
  margin-right: auto;
  margin-left: auto;
}

.bd-example-tabs .nav-tabs {
  margin-bottom: 1rem;
}

.bd-example-popover-static {
  padding-bottom: 1.5rem;
  background-color: #f9f9f9;
}

.bd-example-popover-static .popover {
  position: relative;
  display: block;
  float: left;
  width: 260px;
  margin: 1.25rem;
}

.tooltip-demo a {
  white-space: nowrap;
}

.bd-example-tooltip-static .tooltip {
  position: relative;
  display: inline-block;
  margin: 10px 20px;
  opacity: 1;
}

.scrollspy-example {
  position: relative;
  height: 200px;
  margin-top: .5rem;
  overflow: auto;
}

.scrollspy-example-2 {
  position: relative;
  height: 350px;
  overflow: auto;
}

.bd-example-border-utils [class^="border"] {
  display: inline-block;
  width: 5rem;
  height: 5rem;
  margin: .25rem;
  background-color: #f5f5f5;
}

.bd-example-border-utils-0 [class^="border"] {
  border: 1px solid #dee2e6;
}

.highlight {
  padding: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: #f8f9fa;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

@media (min-width: 576px) {
  .highlight {
    padding: 1.5rem;
  }
}

.bd-content .highlight {
  margin-right: -15px;
  margin-left: -15px;
}

@media (min-width: 576px) {
  .bd-content .highlight {
    margin-right: 0;
    margin-left: 0;
  }
}

.highlight pre {
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  background-color: transparent;
  border: 0;
}

.highlight pre code {
  font-size: inherit;
  color: #212529;
}

.btn-bd-primary {
  font-weight: 600;
  color: #0065b3;
  border-color: #0065b3;
}

.btn-bd-primary:hover, .btn-bd-primary:active {
  color: #fff;
  background-color: #0065b3;
  border-color: #0065b3;
}

.btn-bd-primary:focus {
  box-shadow: 0 0 0 3px rgba(0, 101, 179, 0.25);
}

.btn-bd-download {
  font-weight: 600;
  color: #0065b3;
  border-color: #0065b3;
}

.btn-bd-download:hover, .btn-bd-download:active {
  color: #2a2730;
  background-color: #0065b3;
  border-color: #0065b3;
}

.btn-bd-download:focus {
  box-shadow: 0 0 0 3px rgba(0, 101, 179, 0.25);
}

.bd-callout {
  padding: 1.25rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  border: 1px solid #eee;
  border-left-width: .25rem;
  border-radius: 0.25rem;
}

.bd-callout h4 {
  margin-top: 0;
  margin-bottom: .25rem;
}

.bd-callout p:last-child {
  margin-bottom: 0;
}

.bd-callout code {
  border-radius: 0.25rem;
}

.bd-callout + .bd-callout {
  margin-top: -.25rem;
}

.bd-callout-info {
  border-left-color: #5bc0de;
}

.bd-callout-info h4 {
  color: #5bc0de;
}

.bd-callout-warning {
  border-left-color: #f0ad4e;
}

.bd-callout-warning h4 {
  color: #f0ad4e;
}

.bd-callout-danger {
  border-left-color: #d9534f;
}

.bd-callout-danger h4 {
  color: #d9534f;
}

.bd-browser-bugs td p {
  margin-bottom: 0;
}

.bd-browser-bugs th:first-child {
  width: 18%;
}

.bd-brand-logos {
  display: table;
  width: 100%;
  margin-bottom: 1rem;
  overflow: hidden;
  color: #e3e3e3;
  background-color: #f9f9f9;
  border-radius: 0.25rem;
}

.bd-brand-logos .inverse {
  color: #fff;
  background-color: #e3e3e3;
}

.bd-brand-item {
  padding: 4rem 0;
  text-align: center;
}

.bd-brand-item + .bd-brand-item {
  border-top: 1px solid #fff;
}

.bd-brand-item h1,
.bd-brand-item h3 {
  margin-top: 0;
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .bd-brand-item {
    display: table-cell;
    width: 1%;
  }
  .bd-brand-item + .bd-brand-item {
    border-top: 0;
    border-left: 1px solid #fff;
  }
  .bd-brand-item h1 {
    font-size: 4rem;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .bd-brand-item h1 {
    font-size: calc(1.525rem + 3.3vw) ;
  }
}

.color-swatches {
  margin: 0 -5px;
  overflow: hidden;
}

.color-swatches .bd-purple {
  background-color: #e3e3e3;
}

.color-swatches .bd-purple-light {
  background-color: white;
}

.color-swatches .bd-purple-lighter {
  background-color: #e5e1ea;
}

.color-swatches .bd-gray {
  background-color: #f9f9f9;
}

.color-swatch {
  float: left;
  width: 4rem;
  height: 4rem;
  margin-right: .25rem;
  margin-left: .25rem;
  border-radius: 0.25rem;
}

@media (min-width: 768px) {
  .color-swatch {
    width: 6rem;
    height: 6rem;
  }
}

.swatch-blue {
  color: #fff;
  background-color: #007bff;
}

.swatch-indigo {
  color: #fff;
  background-color: #6610f2;
}

.swatch-purple {
  color: #fff;
  background-color: #6f42c1;
}

.swatch-pink {
  color: #fff;
  background-color: #e83e8c;
}

.swatch-red {
  color: #fff;
  background-color: #dc3545;
}

.swatch-orange {
  color: #212529;
  background-color: #fd7e14;
}

.swatch-yellow {
  color: #212529;
  background-color: #ffc107;
}

.swatch-green {
  color: #fff;
  background-color: #28a745;
}

.swatch-teal {
  color: #fff;
  background-color: #20c997;
}

.swatch-cyan {
  color: #fff;
  background-color: #17a2b8;
}

.swatch-white {
  color: #212529;
  background-color: #fff;
}

.swatch-gray {
  color: #fff;
  background-color: #6c757d;
}

.swatch-gray-dark {
  color: #fff;
  background-color: #343a40;
}

.swatch-primary {
  color: #fff;
  background-color: #007bff;
}

.swatch-secondary {
  color: #fff;
  background-color: #6c757d;
}

.swatch-success {
  color: #fff;
  background-color: #28a745;
}

.swatch-info {
  color: #fff;
  background-color: #17a2b8;
}

.swatch-warning {
  color: #212529;
  background-color: #ffc107;
}

.swatch-danger {
  color: #fff;
  background-color: #dc3545;
}

.swatch-light {
  color: #212529;
  background-color: #f8f9fa;
}

.swatch-dark {
  color: #fff;
  background-color: #343a40;
}

.swatch-100 {
  color: #212529;
  background-color: #f8f9fa;
}

.swatch-200 {
  color: #212529;
  background-color: #e9ecef;
}

.swatch-300 {
  color: #212529;
  background-color: #dee2e6;
}

.swatch-400 {
  color: #212529;
  background-color: #ced4da;
}

.swatch-500 {
  color: #212529;
  background-color: #adb5bd;
}

.swatch-600 {
  color: #fff;
  background-color: #6c757d;
}

.swatch-700 {
  color: #fff;
  background-color: #495057;
}

.swatch-800 {
  color: #fff;
  background-color: #343a40;
}

.swatch-900 {
  color: #fff;
  background-color: #212529;
}

.bd-clipboard {
  position: relative;
  display: none;
  float: right;
}

.bd-clipboard + .highlight {
  margin-top: 0;
}

@media (min-width: 768px) {
  .bd-clipboard {
    display: block;
  }
}

.btn-clipboard {
  z-index: 10;
  display: block;
  padding: 2px 10px;
  font-size: 75%;
  color: black;
  background-color: transparent;
  border: 0;
  border-radius: 0.25rem;
}

.btn-clipboard:hover {
  color: black;
  background-color: #fafafa;
}

.btn-clipboard:focus {
  outline: none;
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.bd-placeholder-img-lg {
  font-size: 3.5rem;
}

@media (max-width: 1200px) {
  .bd-placeholder-img-lg {
    font-size: calc(1.475rem + 2.7vw) ;
  }
}

.hll {
  background-color: #ffc;
}

.c {
  color: #999;
}

.k {
  color: #069;
}

.o {
  color: #555;
}

.cm {
  color: #999;
}

.cp {
  color: #099;
}

.c1 {
  color: #999;
}

.cs {
  color: #999;
}

.gd {
  background-color: #fcc;
  border: 1px solid #c00;
}

.ge {
  font-style: italic;
}

.gr {
  color: #f00;
}

.gh {
  color: #030;
}

.gi {
  background-color: #cfc;
  border: 1px solid #0c0;
}

.go {
  color: #aaa;
}

.gp {
  color: #009;
}

.gu {
  color: #030;
}

.gt {
  color: #9c6;
}

.kc {
  color: #069;
}

.kd {
  color: #069;
}

.kn {
  color: #069;
}

.kp {
  color: #069;
}

.kr {
  color: #069;
}

.kt {
  color: #078;
}

.m {
  color: #f60;
}

.s {
  color: #d44950;
}

.na {
  color: #4f9fcf;
}

.nb {
  color: #366;
}

.nc {
  color: #0a8;
}

.no {
  color: #360;
}

.nd {
  color: #99f;
}

.ni {
  color: #999;
}

.ne {
  color: #c00;
}

.nf {
  color: #c0f;
}

.nl {
  color: #99f;
}

.nn {
  color: #0cf;
}

.nt {
  color: #2f6f9f;
}

.nv {
  color: #033;
}

.ow {
  color: #000;
}

.w {
  color: #bbb;
}

.mf {
  color: #f60;
}

.mh {
  color: #f60;
}

.mi {
  color: #f60;
}

.mo {
  color: #f60;
}

.sb {
  color: #c30;
}

.sc {
  color: #c30;
}

.sd {
  font-style: italic;
  color: #c30;
}

.s2 {
  color: #c30;
}

.se {
  color: #c30;
}

.sh {
  color: #c30;
}

.si {
  color: #a00;
}

.sx {
  color: #c30;
}

.sr {
  color: #3aa;
}

.s1 {
  color: #c30;
}

.ss {
  color: #fc3;
}

.bp {
  color: #366;
}

.vc {
  color: #033;
}

.vg {
  color: #033;
}

.vi {
  color: #033;
}

.il {
  color: #f60;
}

.css .o,
.css .o + .nt,
.css .nt + .nt {
  color: #999;
}

.language-bash::before,
.language-sh::before {
  color: #009;
  content: "$ ";
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.language-powershell::before {
  color: #009;
  content: "PM> ";
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.anchorjs-link {
  font-weight: 400;
  color: rgba(0, 123, 255, 0.5);
  transition: color 0.15s ease-in-out, opacity 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .anchorjs-link {
    transition: none;
  }
}

.anchorjs-link:hover {
  color: #007bff;
  text-decoration: none;
}

.algolia-autocomplete {
  display: block !important;
  -ms-flex: 1;
  flex: 1;
}

.algolia-autocomplete .ds-dropdown-menu {
  width: 100%;
  min-width: 0 !important;
  max-width: none !important;
  padding: .75rem 0 !important;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
}

@media (min-width: 768px) {
  .algolia-autocomplete .ds-dropdown-menu {
    width: 175%;
  }
}

.algolia-autocomplete .ds-dropdown-menu::before {
  display: none !important;
}

.algolia-autocomplete .ds-dropdown-menu [class^="ds-dataset-"] {
  padding: 0 !important;
  overflow: visible !important;
  background-color: transparent !important;
  border: 0 !important;
}

.algolia-autocomplete .ds-dropdown-menu .ds-suggestions {
  margin-top: 0 !important;
}

.algolia-autocomplete .algolia-docsearch-suggestion {
  padding: 0 !important;
  overflow: visible !important;
}

.algolia-autocomplete .algolia-docsearch-suggestion--category-header {
  padding: .125rem 1rem !important;
  margin-top: 0 !important;
  font-size: 0.875rem !important;
  font-weight: 600 !important;
  color: #0065b3 !important;
  border-bottom: 0 !important;
}

.algolia-autocomplete .algolia-docsearch-suggestion--wrapper {
  float: none !important;
  padding-top: 0 !important;
}

.algolia-autocomplete .algolia-docsearch-suggestion--subcategory-column {
  float: none !important;
  width: auto !important;
  padding: 0 !important;
  text-align: left !important;
}

.algolia-autocomplete .algolia-docsearch-suggestion--subcategory-inline {
  display: block !important;
  font-size: 0.875rem;
  color: #495057;
}

.algolia-autocomplete .algolia-docsearch-suggestion--subcategory-inline::after {
  padding: 0 .25rem;
  content: "/";
}

.algolia-autocomplete .algolia-docsearch-suggestion--content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  float: none !important;
  width: 100% !important;
  padding: .25rem 1rem !important;
}

.algolia-autocomplete .algolia-docsearch-suggestion--content::before {
  display: none !important;
}

.algolia-autocomplete .ds-suggestion:not(:first-child) .algolia-docsearch-suggestion--category-header {
  padding-top: .75rem !important;
  margin-top: .75rem !important;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.algolia-autocomplete .ds-suggestion .algolia-docsearch-suggestion--subcategory-column {
  display: none !important;
}

.algolia-autocomplete .algolia-docsearch-suggestion--title {
  display: block;
  margin-bottom: 0 !important;
  font-size: 0.875rem !important;
  font-weight: 400 !important;
}

.algolia-autocomplete .algolia-docsearch-suggestion--text {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  padding: .2rem 0;
  font-size: 0.8125rem !important;
  font-weight: 400;
  line-height: 1.25 !important;
  color: #6c757d;
}

.algolia-autocomplete .algolia-docsearch-footer {
  float: none !important;
  width: auto !important;
  height: auto !important;
  padding: .75rem 1rem 0;
  font-size: 0.75rem !important;
  line-height: 1 !important;
  color: #767676 !important;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.algolia-autocomplete .algolia-docsearch-footer--logo {
  display: inline !important;
  overflow: visible !important;
  color: inherit !important;
  text-indent: 0 !important;
  background: none !important;
}

.algolia-autocomplete .algolia-docsearch-suggestion--highlight {
  color: #5f2dab;
  background-color: rgba(154, 132, 187, 0.12);
}

.algolia-autocomplete .algolia-docsearch-suggestion--text .algolia-docsearch-suggestion--highlight {
  box-shadow: inset 0 -2px 0 0 rgba(95, 45, 171, 0.5) !important;
}

.algolia-autocomplete .ds-suggestion.ds-cursor .algolia-docsearch-suggestion--content {
  background-color: rgba(208, 189, 236, 0.15) !important;
}
/*# sourceMappingURL=docs.min.css.map */