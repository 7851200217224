//
// Main navbar
//

.nav {
  flex-direction: column;
}

.bd-navbar {
  flex-shrink: 0;
  background-color: $white;
  border-bottom: 1px solid hsla(0,0%,89%,1);
  padding-left: 4vw;
  padding-right: 6vw;

  @include media-breakpoint-down(md) {
    padding-right: .5rem;
    padding-left: .5rem;
    max-width: 100%;
    width: 100%;

    .navbar-nav-scroll {
      max-width: 100%;

      .navbar-nav {
        padding-bottom: 2rem;
        padding-left: 24px;
        padding-right: 24px;
        overflow-x: auto;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;
      }
    }
  }

  @include media-breakpoint-up(md) {
    @supports (position: sticky) {
      top: 0;
      z-index: 1071; // over everything in bootstrap
    }
  }

  .title {
    color: hsla(0,0%,9%,1);
    font-size: 1.1875rem;
    font-weight: 600;
    line-height: 1.125;
    align-self: center;
    border-bottom: 2px solid transparent;

    &:hover {
      text-decoration: none;
      border-bottom: none;

      &:after {
        content: "";
        display: block;
        border-bottom: 2px solid hsla(0,0%,9%,1);
      }
    }
  }

  .navbar-nav {
    .nav-link {
      padding-right: .5rem;
      padding-left: .5rem;
      color: $bd-purple-light;
      font-size: 1.125rem;
      cursor: pointer;

      &.active,
      &:hover {
        color: $black;
        background-color: transparent;
      }

      &.active {
        font-weight: 400;
      }
    }
  }

  .navbar-nav-svg {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    vertical-align: text-top;
  }

  .dropdown-menu {
    @include font-size(.875rem);
  }

  .dropdown-item.active {
    font-weight: 600;
    color: $gray-900;
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23292b2c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: .4rem .6rem;
    background-size: .75rem .75rem;
  }
}
.tooltip {
  z-index: 1100;
}
.tooltip-inner {
  color: #000;
  background-color: #fff;
  border: 2px solid #000;
  border-radius: 2px;
}
.subnavbar-nav {
  display: flex;
  border-bottom: 1px solid hsla(0,0%,89%,1);
  padding-left: 5vw;
  padding-right: 5vw;
  
  ul, ol {
    margin: 0;
  }
  .btn-clipboard {
    position: inherit;
  }

  @include media-breakpoint-down(md) {
    background: hsla(0,0%,98%,1); 
    padding-left: 2vw;
    padding-right: 2vw;
  }
  
  .nav-link {
    padding: 0.2rem .75em;
    font-size: .875rem;
    cursor: pointer;
    color: hsla(0,0%,0%,1);
  }
  .nav-item:hover {
    background: hsla(0,0%,98%,1);
  }

  .breadcrumb {
    margin: 0;
    padding: 0.2rem 0;
    background-color: transparent;
    font-size: .875rem;

    a {
      color: hsla(206,100%,35%,1);
    }

  }
  .actions {
    display: flex;
    margin-right: 15px;
    list-style-type: none;
    @include media-breakpoint-down(md) {
      margin-right: 0;
      padding-inline-start: 0;
    }
  }
}

.bd-search {
  position: relative; // To contain the Algolia search
  padding: 0;

  @include media-breakpoint-up(md) {
    margin-left: -15px;
  }

  ::-webkit-input-placeholder {color:hsla(0,0%,0%,1);}
  ::-moz-placeholder          {color:hsla(0,0%,0%,1);}/* Firefox 19+ */
  :-moz-placeholder           {color:hsla(0,0%,0%,1);}/* Firefox 18- */
  :-ms-input-placeholder      {color:hsla(0,0%,0%,1);}
  
  &:hover,
  &:focus,
  &:focus-within,
  &:active {
    width: 100%;
    .form-control {
      text-align: left;
      border: 2px solid $bd-purple-bright;
      box-shadow: 0 0 0 0.125rem hsla(206,100%,35%,1);
    }
    .icon {
      display: none;
    }
  }

  .form-control:focus {
    box-shadow: none;
  }

  .form-control {
    font-size: .875rem;
    border-radius: 2px;
    text-align: right;
    border: 1px solid transparent;
  }
}

.bd-search-docs-toggle {
  align-self: flex-end;
  line-height: 1;
  color: $gray-900;
}
