// stylelint-disable declaration-no-important

//
// Right side table of contents
//

.bd-toc {
  @supports (position: sticky) {
    position: sticky;
    top: 0;
    height: 100vh;
    overflow-y: auto;
  }
  order: 2;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  @include font-size(.875rem);

  h6 {
    padding: .125rem 0.875rem;
  }
}

.section-nav {
  padding-left: 0;

  ul {
    padding-left: .875rem;
  }
}
#toc {
  .nav-link.active, 
  .nav-link.active:focus,
  .nav-link.active:hover {
    color: hsla(206,100%,35%,1);
    border-color: hsla(206,100%,35%,1);
  }
  .nav>li>a {
    color: hsla(206,100%,35%,1);
  }
}
.toc-entry {
  display: block;

  &.active > a {
    border-color: hsla(206,100%,35%,1);
  }

  a {
    display: block;
    padding: .125rem 1.5rem;
    color: hsla(206,100%,35%,1);
    border-left: 3px solid transparent;

    &:hover {
      color: $blue;
      text-decoration: underline;
    }
    &:selected {
      border-left: 3px solid hsla(206,100%,35%,1);
    }
    &.current {
      background: #000;
    }
  }
}

//
// Left side navigation
//

.bd-sidebar {
  order: 0;
  // background-color: #f5f2f9;

  @include media-breakpoint-up(md) {
    @supports (position: sticky) {
      position: sticky;
      top: 0;
      z-index: 1000;
      height: 100vh;
    }
  }

  @include media-breakpoint-up(xl) {
    flex: 0 1 320px;
  }
}

.bd-links {
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-right: -15px;
  margin-left: -15px;

  @include media-breakpoint-up(md) {
    @supports (position: sticky) {
      max-height: calc(100vh - 5rem);
      overflow-y: auto;
    }
  }

  // Override collapse behaviors
  @include media-breakpoint-up(md) {
    display: block !important;
  }
}



.tree-expander {
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.tree-expander, .tree-item.is-leaf {
    outline-offset: -.125rem;
}
.tree-expander, .tree-item.is-leaf {
    display: block;
    padding-left: 1rem;
    padding-top: 2px;
    padding-bottom: 2px;
}
.tree-expander-indicator {
    display: inline-block;
    position: absolute;
    top: 6px;
    left: 3px;
    color: hsla(0,0%,37%,1);
    color: var(--text-subtle);
    font-size: .55rem;
    font-weight: 600;
    transition: transform .15s ease-in-out;

    &.active {
      transform: rotate(90deg)
    }
}
.docon-chevron-right-light:before {
    content: "\FF34";
}

.bd-toc-link {
  display: block;
  padding: 0 .675rem;
  @include font-size(.875rem);
  color: rgba(0, 0, 0, .65);

  &:hover {
    color: rgba(0, 0, 0, .85);
    text-decoration: none;
  }
}

.bd-toc-item {
  &.active {

    .bd-toc-link {
      color: rgba(0, 0, 0, .85);
      background-color: transparent;
      font-weight: 400;

      &:hover {
        background-color: transparent;
      }
      &:first-child {
        .tree-expander-indicator {
          transform: rotate(90deg);
        }
      }
      .tree-expander-indicator {
        &.active {
          transform: rotate(90deg);
        }
      }
    }

    &.top-level {
      .bd-toc-link {
        font-weight: 600;
        color: hsla(0,0%,0%,.999);
      }
    }
  }
}

.bd-sidenav {
  display: block;

  .bd-sidenav {
    padding-left: 15px;
  }
}

.active .active:first-child {
  .tree-expander-indicator {
    transform: rotate(90deg);
  }
}

.top-level {
  .tree-expander-indicator {
    display: none;
  }

  &.bd-toc-item.active {
    margin-bottom: 0;
  }
  &.active {
    font-weight: 600;
    background: hsla(0,0%,95%,1);
    color: hsla(0,0%,0%,.999);
  }
}

// All levels of nav
.bd-sidebar {

  .bd-toc-item a {
    padding: .25rem 1.5rem;
  }

  .nav > li a {
      display: block;
      @include font-size(.875rem);
      color: rgba(0, 0, 0, .65);

      &:hover {
        color: rgba(0, 0, 0, .85);
        text-decoration: underline;
        background-color: transparent;
      }
  }
  .nav > li.active {
    ul li a {
      color: rgba(0, 0, 0, .65);
    }
    a {
      color: hsla(0,0%,0%,.999);
    }
  }
}
.bd-toc-item {

  &.active {
    .bd-sidenav {
      display: block;
      li {
        &.active:not(.bd-toc-link) {
          a {
            font-weight: 600;
            background: hsla(0,0%,95%,1);
            color: hsla(0,0%,0%,.999);
          }
          ul {
            li a {
              color: rgba(0, 0, 0, .85);
              background-color: transparent;
              font-weight: 400;
            }
            li.active a {  
              font-weight: 600;
              background: hsla(0,0%,95%,1);
              color: hsla(0,0%,0%,.999);
            }
          }
        }
      }
    }
  }
  .bd-sidenav {
    display: none;
    padding-left: .675em;

    .bd-subsidenav {
      display: none;  
      margin-left: 2em;
      font-weight: 600;
      background: hsla(0,0%,95%,1);
      color: hsla(0,0%,0%,.999);
    }
    .bd-subsidenav-active {
      margin-left: 2em;
      display: block;
    }
    .bd-sidenav-active {
      padding-left: 0;
    }
    .bd-toc-link {
      padding: 0;
    }
  }
  .bd-sidenav-active {
    padding-left: .675em;

    .bd-toc-link {
      padding: 0;
    }

    li.active {
      a {
        font-weight: 600;
        background: hsla(0,0%,95%,1);
        color: hsla(0,0%,0%,.999);
      }
      ul {
        li.active a {
          font-weight: 600;
          background: hsla(0,0%,95%,1);
          color: hsla(0,0%,0%,.999);
        }
        li a {
          color: rgba(0, 0, 0, .85);
          background-color: transparent;
          font-weight: 400;
        }
      }
    }
    .bd-subsidenav {
      display: none;
    }

    .tree-expander-indicator {
      transform: rotate(0deg);
      background: inherit;
      color: inherit;
    }

    .bd-subsidenav-active {
      display: block;
      margin-left: 2em;
      .active a {
        font-weight: 600;
        background: hsla(0,0%,95%,1);
        color: hsla(0,0%,0%,.999);
      }
    }
  }
}
