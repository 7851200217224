// stylelint-disable no-duplicate-selectors, selector-max-combinators, selector-max-compound-selectors, selector-max-type, selector-no-qualifying-type

//
// Automatically style Markdown-based tables like a Bootstrap `.table`.
//

a {
  color: hsla(206,100%,35%,1);
}

#date {
  margin-right: 7px;
  color: hsla(0,0%,37%,1);
  font-size: .875rem;

  &:after {
    padding-left: 5px;
    padding-right: 5px;
    content: "•";
  }
}

#contributors {
  a {
    margin-right: 5px;
  }
  img {
    width: 24px;
    height: 24px;
    border-radius: 24px;
  }
}

.bd-content {
  order: 1;
  padding-top: .25em;

  // Hack the sticky header
  > h2[id],
  > h3[id],
  > h4[id] {
    pointer-events: none;

    &::before {
      display: block;
      height: 6rem;
      margin-top: -6rem;
      content: "";
    }
  }

  > table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;

    @include media-breakpoint-down(md) {
      display: block;
      overflow-x: auto;

      &.table-bordered {
        border: 0;
      }
    }

    // Cells
    > thead,
    > tbody,
    > tfoot {
      > tr {
        > th,
        > td {
          padding: $table-cell-padding;
          vertical-align: top;
          border: 1px solid $table-border-color;

          > p:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    // Prevent breaking of code (e.g., Grunt tasks list)
    td:first-child > code {
      white-space: nowrap;
    }
  }
}

.bd-content-title {
  display: block;
  font-weight: 600;
  pointer-events: auto;
}

//
// Docs sections
//

.bd-content {
  > h2 {
    font-size: 1.75rem;
  }

  > h3 {
    font-size: 1.1875rem;
  }

  > h4 {
    @include font-size($h4-font-size);
  }

  > h2:not(:first-child) {
    margin-top: 3rem;
  }

  > h3 {
    margin-top: 1.5rem;
  }

  > ul li,
  > ol li {
    margin-bottom: .25rem;
  }

  > p img {
    width: 100%
  }

  > p {
    word-break: break-word;
  }

  @include media-breakpoint-up(lg) {
    > ul,
    > ol,
    > p {
      max-width: 80%;
    }
  }
}

.bd-title {
  margin-top: 1rem;
  margin-bottom: .5rem;
  font-weight: 600;
  font-size: 2.5rem;
}

.bd-lead {
  @include font-size(1.5rem);
  font-weight: 300;

  @include media-breakpoint-up(lg) {
    max-width: 80%;
  }
}

.bd-text-purple { color: $bd-purple; }
.bd-text-purple-bright { color: $bd-purple-bright; }

.container-fluid {

  @include media-breakpoint-up(md) {
    padding-left: 5vw;
    padding-right: 5vw;
  }
}
