// clipboard.js
//
// JS-based `Copy` buttons for code snippets.

.bd-clipboard {
  position: relative;
  display: none;
  float: right;

  + .highlight {
    margin-top: 0;
  }

  @include media-breakpoint-up(md) {
    display: block;
  }
}

.btn-clipboard {
  z-index: 10;
  display: block;
  padding: 2px 10px;
  @include font-size(75%);
  color: hsla(0,0%,0%,1);
  background-color: transparent;
  border: 0;
  @include border-radius;

  &:hover {
    color: hsla(0,0%,0%,1);
    background-color: hsla(0,0%,98%,1);
  }
  &:focus {
    outline: none;
  }
}
